<template>
  <!--
    This example requires updating your template:

    ```
    <html class="h-full bg-gray-50">
    <body class="h-full">
    ```
  -->
  <div class="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div v-show="collectingEmail" class="sm:mx-auto sm:w-full sm:max-w-md">
      <img src="/img/icon.svg" class="mx-auto h-12 w-auto text-emerald-600" alt="Locktor" />
      <h2 class="mt-6 text-center text-2xl font-bold text-gray-900">
        Log in to your account
      </h2>
      <p class="mt-2 text-center text-sm text-gray-600">
        Or
        {{ ' ' }}
        <router-link to="/register" class="font-medium text-emerald-600 hover:text-emerald-500">
          create an account today
        </router-link>
      </p>
    </div>

    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <div class="px-4 sm:rounded-lg sm:px-10">
        <form v-show="collectingEmail" class="mt-12 space-y-6" @submit.prevent="trySignIn" method="POST" action="#">
          <div>
            <label for="email" class="block text-sm font-medium text-gray-700">
              Email address
            </label>
            <div class="mt-1">
              <input v-model="email" id="email" name="email" type="email" autocomplete="email" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm" />
            </div>
          </div>

          <div>
            <loadable-button :loading="working" type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
              Continue
            </loadable-button>
          </div>
        </form>

        <form v-show="collectingOtp" class="space-y-6" @submit.prevent="verifyOtp" method="post">
          <header>
            <div class="w-full flex justify-center">
              <img class="w-28 h-auto sm:w-36" src="/img/undraw_opened_re_i38e.svg" />
            </div>
            <h2 class="mt-10 text-center text-2xl font-bold text-gray-800">
              Check your Inbox
            </h2>
            <p class="mt-4 max-w-sm mx-auto text-gray-700">
              We sent a verification code to your email address to make sure it's really you.
              Enter the code below:
            </p>
            <p class="mt-2 text-sm text-gray-600">
              If you don't see the email soon, check your spam folder.
            </p>
          </header>

          <div class="mt-8">
            <label for="otpCode" class="block text-sm font-medium text-gray-700">

            </label>
            <div class="mt-1">
              <input
                autocomplete="off"
                v-model="otpCode"
                type="text"
                id="otpCode"
                name="otpCode"
                required
                minlength="6" maxlength="6"
                class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm text-xl text-center tracking-widest placeholder-gray-400 focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-2xl"
                placeholder="Code"
              />
            </div>
          </div>

          <div>
            <loadable-button :loading="working" type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
              Continue
            </loadable-button>
          </div>
        </form>

        <form v-show="collectingMp" class="" @submit.prevent="completeLogin" method="post">
          <header>
            <img src="/img/icon.svg" class="mx-auto h-12 w-auto text-emerald-600" alt="Locktor" />
            <p class="mt-6 text-gray-700 text-center font-">
              <span v-show="authUser" class="block font-semibold">
                {{ authUser?.displayName }}
              </span>
              {{ authUser?.email || email }}
            </p>
          </header>

          <div class="mt-20">
            <label for="masterPassword" class="block text-sm font-medium text-gray-700">
              Master Password
            </label>
            <div class="mt-1 relative">
              <input
                v-model="masterPassword"
                :type="passwordHtmlType"
                id="masterPassword"
                name="masterPassword"
                required
                class="appearance-none block w-full px-3 py-2 pr-8 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 sm:text-sm"
              />
              <div class="absolute inset-y-0 right-0 flex items-center pr-3">
                <button @mousedown="togglePasswordVisibility" @mouseup="togglePasswordVisibility" type="button" aria-hidden="true">
                  <EyeIcon class="h-5 w-5 text-gray-400" />
                </button>
              </div>
            </div>
          </div>

          <div class="mt-6">
            <loadable-button :loading="working" type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
              {{ authUser ? 'Open Vault' : 'Log In' }}
            </loadable-button>
          </div>
          <p class="mt-6 text-center text-sm text-gray-700">
            Not you?
            <button type="button" class="underline text-emerald-800" @click="signOut">
              Sign out
            </button>
          </p>
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { EyeIcon } from '@heroicons/vue/24/outline'
import { useAuthStore } from '@/stores/auth';
import LoadableButton from '@/components/LoadableButton.vue'
import * as functions from '@/crypto/functions'
import { useKpkStore } from '@/stores/kpk'
import { useVaultsStore } from '@/stores/vaults'
import notifications from '@/notifications'
import { signInWithCustomToken } from '@firebase/auth';
import { auth } from '@/firebase';
import { mapStores } from 'pinia';
import { tracker } from '@/amplitude'

interface PasswordInfo {
  salt: string,
  iterations: number,
  challenge: {
    iv: string,
  }
}
const apiRoot = import.meta.env.VITE_API_URL

export default defineComponent({
    components: { EyeIcon, LoadableButton },
    data() {
      return {
        step: <'email' | 'otp' | 'mp'> 'email',
        email: '',
        masterPassword: '',
        passwordHtmlType: <'password' | 'text'> 'password',
        otpCode: <string|null> null,

        working: false,
        verificationId: <string|null> null,
        bridgeToken: <string|null> null,
      }
    },

    computed: {
      // NOTE: the following line is why the entire exported component
      // might be marked in red within your (VSCode) editor.
      ...mapStores(useAuthStore),

      verified() {
        // @ts-ignore
        return this.authStore?.signedIn
      },
      authUser() { return this.authStore.user },

      collectingEmail () { return !this.verified && this.step === 'email' },
      collectingOtp () { return !this.verified && this.step === 'otp' },
      collectingMp () { return this.verified || this.step === 'mp' },
    },

    methods: {
      togglePasswordVisibility() {
        this.passwordHtmlType = this.passwordHtmlType === 'password'
          ? 'text'
          : 'password'
      },

      /**
       * Try starting the verification process for this customer.
       */
      async trySignIn(): Promise<void> {
        this.working = true

        const login = await fetch(`${apiRoot}/auth/login`, {
          method: 'post',
          body: JSON.stringify({ email: this.email }),
          headers: { 'Content-Type': 'application/json' },
        })

        if (!login.ok) {
          notifications.warning(
            'Something went wrong',
            'Please check your information and try again.'
          )
          this.working = false

          return
        }

        // store verification id and request otp
        this.verificationId = (await login.json()).verification
        this.step = 'otp'
        this.working = false
      },

      /**
       * Verify a customer's OTP code response.
       *
       * @returns {Promise<void>}
       */
      async verifyOtp(): Promise<void> {
        this.working = true

        const verified = await fetch(`${apiRoot}/auth/check-otp`, {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            verification: this.verificationId,
            response: this.otpCode
          })
        })

        if (!verified.ok) {
          this.working = false
          return notifications.warning(
            'That didn\'t work',
            'The code you entered may be invalid or expired.'
          )
        }

        this.bridgeToken = (await verified.json()).token
        this.step = 'mp'
        this.working = false
      },

      /**
       * @returns {Promise<void>}
       */
      async completeLogin() {
        this.working = true

        const getMp = await this.getMpInfo()

        if (!getMp.ok) {
          notifications.error(
            'Something went wrong',
            'Please try again in a few minutes.'
          )
          this.working = false

          return
        }

        const passInfo = <PasswordInfo>(await getMp.json())
        const km = await this.deriveKeyingMaterial(this.masterPassword, passInfo)

        const challenge = await this.verifyChallenge(
          km,
          Uint32Array.from(functions.toNumberArray(passInfo.challenge.iv)),
          this.email || <string>this.authUser?.email
        )

        if (!challenge.ok) {
          notifications.warning(
            'That didn\'t work',
            'Please check your password and try again.'
          )
          this.working = false

          return
        }

        this.authStore.refreshSession()

        const nextPage = this.$route.query.redirect
          ? this.$route.query.redirect
          : '/'

        if (this.verified) {
          tracker.reauthenticated()
          await useKpkStore().pull(km)
          await useVaultsStore().pullAll()

          this.$router.push(nextPage)
          return
        }

        const { idToken } = await challenge.json()

        /** @todo test with invalid token */
        const userCredential = await signInWithCustomToken(auth, idToken)
          .catch(error => {
            this.working = false
          })

        if (userCredential) {
          await useKpkStore().pull(km)
          await useVaultsStore().pullAll()
          this.authStore.refreshSession()

          this.$router.push(nextPage)
          return
        }

        notifications.error('Something went wrong', 'Please try again in a minute.')
      },

      signOut() {
        this.authStore.signOut()
        this.step = 'email'
      },

      /**
       * Get Master Password metadata for this customer.
       */
      async getMpInfo() {
        const headers = <HeadersInit>(
          this.verified
            ? { Authorization: `Bearer ${await this.authUser?.getIdToken()}` }
            : {
              Authorization: `Bearer ${this.bridgeToken}`,
              'X-Verification-Id': <string>this.verificationId
            }
        )

        return fetch(`${apiRoot}/master-passwords/me`, { headers })
      },

      /**
       *
       * @param {CryptoKey} key
       * @param {Uint32Array} iv
       * @param {string} email
       */
      async verifyChallenge(key: CryptoKey, iv: Uint32Array, email: string): Promise<Response> {
        const challenge = functions.arrayBufferToText(
          await crypto.subtle.encrypt(
            { name: 'AES-GCM', iv },
            key,
            (new TextEncoder).encode(`${iv}|${email.toLowerCase()}`)
          )
        )

        const token = this.verified ? await this.authUser?.getIdToken() : this.bridgeToken

        const check = await fetch(`${apiRoot}/auth/challenge`, {
          method: 'post',
          body: JSON.stringify({ challenge }),
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            'X-Verification-Id': <string>this.verificationId
          },
        })

        return check
      },

      /**
       * Derive keying material using password info.
       *
       * @param {string} password
       * @param {PasswordInfo} info
       *
       * @returns {Promise<CryptoKey>}
       */
      async deriveKeyingMaterial(password: string, info: PasswordInfo): Promise<CryptoKey> {
        const normalizedPassword = String(password).trim().normalize('NFKD')
        const passwordAsKey = await functions.passwordToCryptoKey(new TextEncoder().encode(normalizedPassword))

        const salt = Uint32Array.from(functions.toNumberArray(info.salt))
        const MK = await functions.deriveMk(passwordAsKey, salt, info.iterations)

        return await functions.deriveKeyFromMk(MK, salt)
      }
    }
})

</script>
