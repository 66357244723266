import { createRouter, createWebHistory, createWebHashHistory, useRouter } from 'vue-router'
import type { RouteLocation, RouteLocationRaw } from 'vue-router'
import { teamsRoutes, teamsGuard, teamsRouteNames } from './teams'
import { defaultRoutes, defaultRoutesGuard, defaultRouteNames } from './default'

// https://router.vuejs.org/guide/advanced/meta.html#TypeScript
declare module 'vue-router' {
  interface RouteMeta {
    requiresAuth?: boolean
    layout?: string

    teams?: {
      requireAuth?: boolean
      guestOnly?: boolean
    }
  }
}

const routeNames = Object.freeze({
  ...defaultRouteNames,
  ...teamsRouteNames,
})

/*
 - if local, add all routes
 - if on teams, only add teams routes
 - else, only add pass-manager routes
 */
let routes = defaultRoutes
let guards = [defaultRoutesGuard]
;(() => {
  if (!(window && window.location)) {
    return
  }
  routes = guards = []

  if (window.location.host.startsWith('localhost:')) {
    // note that `concat` returns a fresh array and doesn't touch existing ones
    routes = defaultRoutes.concat(teamsRoutes)
    guards.push(defaultRoutesGuard, teamsGuard)
    return
  }

  if (window.location.host.startsWith('teams.')) {
    routes = teamsRoutes
    guards = [teamsGuard]
    return
  }

  routes = defaultRoutes
  guards = [defaultRoutesGuard]
})()

const router = createRouter({
  history: import.meta.env.VITE_TARGET_PLATFORM === 'electron'
    ? createWebHashHistory()
    : createWebHistory(import.meta.env.BASE_URL),
  routes,
})

guards.forEach(g => router.beforeEach(g))


export const routeIsCurrent = (given: RouteLocation | RouteLocationRaw) => {
  const router = useRouter()
  given = router.resolve(given)
  /**
   * while there's an adjacent parent:
   *   if given route's name matches the current route's parent exactly, return true
   */
  const currentRoute = router.currentRoute.value
  const matched = currentRoute.matched

  let match = null
  for (let i = 1; match !== undefined; i++) {
    match = matched[matched.length - i]
    if (match && (given.path === match.path)) {
      return true
    }
  }

  return false
}

export default router

export { routeNames }