import { defineStore } from 'pinia'
import * as funcs from '@/crypto/functions'
import { useAuthStore } from './auth'
import notifications from '@/notifications'

const apiRoot = import.meta.env.VITE_API_URL

/**
 * Key Protection Keys store.
 */
export const useKpkStore = defineStore('kpk', {
  state: () => ({
    privateKey: <CryptoKey|null> null,
    publicKey: <CryptoKey|null> null,
  }),

  getters: {
    keyedUp: store => ![store.privateKey, store.publicKey].includes(null)
  },

  actions: {
    /**
     * Pull this customer's key-protection keys.
     *
     * @param {CryptoKey} km "Keying material" used to decrypt the private RSA key.
     *
     * @returns {Promise<boolean>}
     */
    async pull(km: CryptoKey): Promise<boolean> {
      const { user } = useAuthStore()

      const response = await fetch(`${apiRoot}/kpk`, {
        headers: { Authorization: `Bearer ${await user?.getIdToken()}`, }
      })

      if (!response.ok) {
        notifications.error(
          'Something went wrong',
          'We were unable to pull your secrets. Please try again in a minute.'
        )

        return false
      }

      const pair = await response.json()
      await this.importPublicKey(pair.public.key)
      await this.importPrivateKey(pair.private.cipher, km, pair.private.iv)

      return true
    },

    async importPublicKey(source: string): Promise<void> {
      this.$patch({
        publicKey: await funcs.importPublicKey(
          funcs.textToArrayBuffer(source)
        )
      })
    },

    async importPrivateKey(source: string, key: CryptoKey, iv: string): Promise<void> {
      this.$patch({
        privateKey: await funcs.importPrivateKey(
          funcs.textToArrayBuffer(source),
          key,
          Uint32Array.from(funcs.toNumberArray(iv))
        ),
      })
    }
  }
})
