<template>
  <TransitionRoot :show="open" as="template" @after-leave="query = ''" appear>
    <Dialog as="div" class="relative z-10" @close="close">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 scale-95" enter-to="opacity-100 scale-100" leave="ease-in duration-200" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95">
          <DialogPanel class="mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
            <Combobox @update:modelValue="onSelect">
              <div class="relative">
                <MagnifyingGlassIcon class="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400" aria-hidden="true" />
                <ComboboxInput class="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm" placeholder="Find anything..." @change="query = $event.target.value" />
              </div>

              <ComboboxOptions v-if="filteredItems.length > 0" static class="max-h-96 scroll-py-3 overflow-y-auto p-3">
                <ComboboxOption v-for="(item, key) in filteredItems" :key="`fi-r-${key}`" :value="item" as="template" v-slot="{ active }">
                  <li :class="['flex cursor-default select-none rounded-xl p-3', active && 'bg-gray-100']">
                    <img :src="vaultsStore.possibleIconFor(new WebsiteLogin(item))" :class="['flex h-10 w-10 flex-none items-center justify-center rounded-lg']" />

                    <div class="ml-4 flex-auto">
                      <p :class="['text-sm font-medium', active ? 'text-gray-900' : 'text-gray-700']">
                        {{ item.fields?.title }}
                      </p>
                      <p :class="['text-sm', active ? 'text-gray-700' : 'text-gray-500']">
                        {{ item.fields?.username }}
                        <span class="font-bold">&nbsp;&middot;&nbsp;</span>
                        <i class="text-x text-gray-400">
                          {{ host(<string>item.fields?.website || '') }}
                        </i>
                      </p>
                    </div>
                  </li>
                </ComboboxOption>
              </ComboboxOptions>

              <div v-if="query !== '' && filteredItems.length === 0" class="py-14 px-6 text-center text-sm sm:px-14">
                <ExclamationCircleIcon type="outline" name="exclamation-circle" class="mx-auto h-6 w-6 text-gray-400" />
                <p class="mt-4 font-semibold text-gray-900">No results found</p>
                <p class="mt-2 text-gray-500">
                  Nothing in your account matches that search term. Please try again.
                </p>
              </div>
            </Combobox>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import lunr from 'lunr'
import { MagnifyingGlassIcon } from '@heroicons/vue/20/solid'
import { ExclamationCircleIcon } from '@heroicons/vue/24/outline'
import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import { WebsiteLogin, useVaultsStore } from '@/stores/vaults'
import { tracker } from '@/amplitude'

const router = useRouter()
const vaultsStore = useVaultsStore()

const props = defineProps<{
  open: boolean
  close: () => void|boolean
}>()

const query = ref('')

const index = computed(() => lunr(function () {
  this.ref('id')
  this.field('title', { boost: 2 })
  this.field('username')
  this.field('website')

  for (const vid in vaultsStore.vaults) {
    const vault = vaultsStore.vaults[vid]
    const ids = Object.keys(vault.items)

    ids.forEach(sid => {
      const doc = {
        id: `${vid}||${sid}`,
        title: vault.items[sid].fields?.title,
        username: vault.items[sid].fields?.username,
        website: vault.items[sid].fields?.website,
      }
      this.add(doc)
    })
    this.build()
  }
}))

const filteredItems = computed(() => {
  const results = query.value === ''
    ? []
    : index.value.search(`*${query.value}*`)

  return results.map(match => {
    const [vid, sid] = match.ref.split('||')

    return Object.assign(
      { href: `/secrets/${vid}/${sid}` },
      vaultsStore.getSecret(vid, sid)
    )
  })
})

function onSelect(item: { href: Location & string }) {
  tracker.foundSearchItem()
  router.push(item.href)
  props.close()
}

function host(url: string): string {
  return (new URL(url)).host
}
</script>
