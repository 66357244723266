import { auth } from './firebase'
import type { User } from '@firebase/auth'

interface CommonHeaders {
  Authorization?: string
  'Content-Type'?: string
}

const meta = {
  baseURL: <string>import.meta.env.VITE_API_URL,
  user: <User | null>null
}

auth.onIdTokenChanged(user => {
  if (user === null && meta.user) {
    // clear token if no one's logged in
    meta.user = null
    return
  }
  meta.user = user
})

/*
 | Preferred Api:
 | --------------
 |
 | api.get('/auth/')
 | api.post('/quotes/233', { body: JSON.stringify(...) })
 */

const api = {
  /**
   * `fetch()` a resource, applying default application config to the request.
   *
   * @returns
   */
  async fetch(url: string | URL, opts: RequestInit) {
    const urlIsString = typeof url === 'string' || (url instanceof String)

    // prepend baseURL if appropriate
    if (urlIsString && !(<string>url).startsWith('http')) {
      url = meta.baseURL + url
    }

    // merge headers
    const headers: CommonHeaders = {}

    if (meta.user) {
      headers.Authorization = `Bearer ${await meta.user?.getIdToken()}`
    }
    if (opts.method?.toLowerCase() !== 'get') {
      headers['Content-Type'] = 'application/json'
    }
    opts.headers = Object.assign(headers, opts.headers)

    return fetch(url, opts)
  },

  /**
   * Make a `GET` request using default application config.
   *
   * @returns
   */
  get(url: string | URL, opts?: RequestInit) {
    opts = opts ? opts : {}
    opts.method = 'get'

    return this.fetch(url, opts)
  },

  /**
   * Make a POST request using default application config.
   *
   * @returns
   */
  post(url: string | URL, opts: RequestInit) {
    opts.method = 'post'

    return this.fetch(url, opts)
  },

  put() { return },

  patch(url: string | URL, opts: RequestInit) {
    opts.method = 'patch'

    return this.fetch(url, opts)
  },
  delete() { return },

  helpers: {
    dateToString(date: string | any) {
      if (typeof date === 'string') {
        return date
      }

      if (typeof date === 'object') {
        if (Object.keys(date).includes('$date') && typeof date.$date === 'string') {
          return date.$date
        }
      }

      return null
    }
  }
}

export default api
