<template>
  <nav class="" aria-label="Sidebar">
    <router-link to="/" class="rounded flex items-center gap-x-4 px-3 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100">
      <ProfileAvatar class="h-10 w-10 rounded-full bg-gray-50" />
      <span class="sr-only">Your profile</span>
      <div class="min-w-0 flex-1">
        <p class="text-sm font-medium text-gray-900">{{ user?.displayName }}</p>
        <p class="flex items-center gap-x-1 truncate text-sm font-normal text-gray-500">
          <OrbitIcon class="h-3 w-3 text-indigo-600" />
          <span>{{ (gameStats?.xp || 0) }} XP</span>
        </p>
      </div>
      <div id="" :class="`px-2 py-1 bg-${rankColor}-100 rounded text-${rankColor}-800 text-center border-2 border-${rankColor}-500`">
        <span class="block text-[7px] leading-tight font-semibold uppercase">
          Level
        </span>
        <span class="block text-base leading-tight">{{ gameStats?.rank }}</span>
      </div>
    </router-link>

    <div class="mt-2 border-b border-gray-300"></div>
    <div class="mt-6">
      <div class="flex justify-between mb-1">
        <span class="text-xs font-medium text-gray-500 tracking-wide">
          Reach Level {{ (gameStats?.rank || 1) + 1 }}
          <UpIconSolid class="inline w-[18px] h-[18px] text-orange-600" />
        </span>
        <span class="text-xs font-medium text-gray-600 uppercase">
        </span>
      </div>
      <div class="relative w-full bg-gray-200 rounded-full h-5">
        <div
          :class="[percentageOfNextRank < 33 ? 'text-gray-500' : 'text-amber-900']"
          class="absolute w-full h-5 text-sm text-center font-semibold tracking-wide"
        >
          {{ gameStats?.xp || 5 }} of
          <OrbitIcon class="inline h-3 w-3 mb-[1px]" />
          {{ nextRankPoints }} XP
        </div>
        <div class="bg-amber-300 h-5 rounded-full transition duration-300" :style="{ width: `${percentageOfNextRank}%` }"></div>
      </div>
    </div>

    <nav class="mt-16 space-y-1">
      <router-link v-for="item in navigation" :key="item.name" :to="item.href" :class="[item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'flex items-center px-3 py-2 text-sm font-medium rounded-md']" :aria-current="item.current ? 'page' : undefined">
        <component :is="item.icon" :class="[item.current ? 'text-gray-500' : 'text-gray-400', 'flex-shrink-0 -ml-1 mr-3 h-6 w-6']" aria-hidden="true" />
        <span class="truncate">
          {{ item.name }}
        </span>
        <span v-if="item.count" :class="[item.current ? 'bg-gray-50' : 'bg-gray-200 text-gray-600', 'ml-auto inline-block py-0.5 px-3 text-xs rounded-full']">
          {{ item.count }}
        </span>
      </router-link>
    </nav>

    <button @click="lock" class="mt-1" :class="['text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'flex items-center px-3 py-2 text-sm font-medium rounded-md']" :aria-current="undefined">
      <LockClosedIcon :class="['text-gray-500', 'flex-shrink-0 -ml-1 mr-3 h-6 w-6']" aria-hidden="true" />
      <span class="truncate">Lock Vault</span>
    </button>
  </nav>
</template>

<style scoped>
#hexagon {
  width: 25px;
  height: 14.43375px;
  background: red;
  position: relative;
}
#hexagon::before {
  content: "";
  position: absolute;
  top: -7.216875px;
  left: 0;
  width: 0;
  height: 0;
  border-left: 12.5px solid transparent;
  border-right: 12.5px solid transparent;
  border-bottom: 7.216875px solid red;
}
#hexagon::after {
  content: "";
  position: absolute;
  bottom: -7.216875px;
  left: 0;
  width: 0;
  height: 0;
  border-left: 12.5px solid transparent;
  border-right: 12.5px solid transparent;
  border-top: 7.216875px solid red;
}
</style>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useAuthStore } from '@/stores/auth'
import {
  HomeIcon,
  LockClosedIcon,
} from '@heroicons/vue/24/outline'
import OrbitIcon from '@/components/icons/MDI/OrbitIcon.vue'
import UpIconSolid from '@/components/icons/Teeny/UpIconSolid.vue'
import ProfileAvatar from './ProfileAvatar.vue'
import { pointsNeededForRank } from '@/game'

const navigation = [
  { name: 'Dashboard', href: '/', icon: HomeIcon, current: true },
  // { name: 'Team', href: '#', icon: UsersIcon, current: false },
  // { name: 'Projects', href: '#', icon: FolderIcon, current: false, count: '19' },
  // { name: 'Calendar', href: '#', icon: CalendarIcon, current: false, count: '20+' },
  // { name: 'Documents', href: '#', icon: InboxIcon, current: false },
  // { name: 'Reports', href: '#', icon: ChartBarIcon, current: false },
]

const lock = () => window.location.reload()

const user = ref(useAuthStore().user)
const gameStats = ref(useAuthStore().session?.game)

const nextRank = computed(() => (gameStats.value?.rank || 1) + 1)
const nextRankPoints = computed(() => pointsNeededForRank(nextRank.value))
const percentageOfNextRank = computed(() => Math.min(
    Math.round(((gameStats.value?.xp || 5) / nextRankPoints.value) * 100),
    97,
  )
)
const rankColor = computed(() => {
  const rank =  gameStats.value?.rank || 1

  if (rank >= 9) return 'purple'
  if (rank >= 6) return 'teal'
  if (rank >= 3) return 'blue'

  return 'slate'
})

</script>
