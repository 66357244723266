<template>
  <img v-if="avatarUrl" :src="`${avatarUrl}?ver=${version}`" alt="Your profile photo" />
  <svg v-else class="text-gray-500 bg-gray-200" fill="currentColor" viewBox="0 0 24 24">
    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
  </svg>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useAuthStore } from '@/stores/auth'

defineProps<{ version?: number }>()

const authStore = useAuthStore()
const avatarUrl = computed(() => authStore.session?.avatarUrl)
</script>