import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'
import { initSentry } from './sentry'
import './amplitude'

const app = createApp(App)

initSentry(app, router)

app.use(createPinia())
app.use(router)
/** @todo remove on updating to Vue 3.3+ */
app.config.unwrapInjectedRef = true

app.mount('#app')
