<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid'
import { BuildingOffice2Icon } from '@heroicons/vue/24/outline'
import { useSessionStore } from '@/stores/session'

const router = useRouter()
const sessionStore = useSessionStore()
const { session, currentOrg } = storeToRefs(sessionStore)

function switchOrg(newOrgId: string) {
  if (newOrgId === currentOrg.value?.id) {
    return
  }

  sessionStore.setCurrentOrg(newOrgId)
  try {
    const url = new URL(window.location.origin + router.currentRoute.value.fullPath)
    url.searchParams.set('r', String(Math.ceil(Math.random() * 10000000)))
    router.push(url.pathname + url.search + url.hash)
  }
  catch {
    //
  }
}

</script>


<template>
  <Menu as="div" class="relative inline-block text-left">
    <div>
      <MenuButton
        :class="[(session?.ownedOrgIds?.length || 0) > 1 ? 'shadow-sm hover:bg-gray-50' : '']"
        class="inline-flex w-full items-center justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm text-slate-700 hover:text-slate-900"
      >
        <BuildingOffice2Icon class="h-4 w-auto text-emerald-600" />
        <span class="max-w-36 truncate">{{ currentOrg?.name }}</span>
        <ChevronUpDownIcon v-if="(session?.ownedOrgIds?.length || 0) > 1" class="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
      </MenuButton>
    </div>

    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
      <MenuItems
        v-if="(session?.ownedOrgIds?.length || 0) > 1"
        class="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div class="py-1">
          <MenuItem v-slot="{ active }" v-for="org in session?.orgs" :key="org.id">
            <a href="#" @click="switchOrg(org.id)"
              :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'group flex items-center px-4 py-2 text-sm']">
              <CheckIcon v-if="org.id == currentOrg?.id" class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
              <span v-else class="mr-3 w-5"></span>
              {{ org.name }}
            </a>
          </MenuItem>
        </div>

      </MenuItems>
    </transition>
  </Menu>
</template>
