import type { NavigationGuardWithThis, RouteRecordRaw } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import { useKpkStore } from '@/stores/kpk'
import FreshLogin from '@/views/Login/FreshLogin.vue'

export const defaultRouteNames = Object.freeze({
  'keyring.dashboard': 'dashboard',
  'keyring.addItem': 'add-secret',
  'keyring.viewItem': 'view-secret',
  'keyring.choosePlan': 'choose-plan',
  'keyring.register': 'register',
  'keyring.login': 'login',
  'keyring.settings': 'settings',
})

// a.k.a password-manager routes
export const defaultRoutes = <RouteRecordRaw[]>[
  {
    path: '/',
    name: 'dashboard',
    meta: { requiresAuth: true, layout: 'password-manager-dashboard' },
    component: () => import('../views/Dashboard.vue')
  },
  {
    path: '/new',
    name: 'add-secret',
    meta: { requiresAuth: true, layout: 'password-manager-dashboard' },
    component: () => import('../views/AddSecret/AddSecret.vue')
  },
  {
    path: '/secrets/:vaultId/:secretId',
    name: 'view-secret',
    meta: { requiresAuth: true, layout: 'password-manager-dashboard' },
    component: () => import('@/views/ViewSecret/ViewSecret.vue')
  },
  {
    path: '/plans/choose',
    name: 'choose-plan',
    meta: { requiresAuth: true, layout: 'auth' },
    component: () => import('@/views/ChoosePlan.vue'),
  },
  {
    path: '/register',
    name: 'register',
    meta: { layout: 'auth', guestOnly: true },
    // route level code-splitting
    // this generates a separate chunk (About.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/Register.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: { layout: 'auth', guestOnly: true },
    component: FreshLogin,
  },
  {
    path: '/settings/:tab',
    name: 'settings',
    alias: '/settings',
    meta: { requiresAuth: true, layout: 'password-manager-dashboard' },
    component: () => import('@/views/Settings/Settings.vue'),
  },
]

export const defaultRoutesGuard = <NavigationGuardWithThis<undefined>>(
  (to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const guestOnly = to.matched.some(record => record.meta.guestOnly);

    // If we don't need to guard this page (i.e. it's ok for every
    // kind of user to see), we can move forward quickly
    if (requiresAuth === undefined || guestOnly === undefined) {
      return next()
    }

    // if it needs guarding tho, we guard
    const authStore = useAuthStore()
    const kpkStore = useKpkStore()
    const someonesLoggedIn = authStore.signedIn && kpkStore.keyedUp

    if (guestOnly && someonesLoggedIn) {
      if (to.query.redirect) {
        return next(to.query.redirect as string)
      }
      return next('/')
    }

    if (requiresAuth && !someonesLoggedIn) {
      return next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    }

    return next();
  }
)
