<template>
  <div class="min-h-screen relative pb-20 bg-gray-50">
    <!-- When the mobile menu is open, add `overflow-hidden` to the `body` element to prevent double scrollbars -->
    <Popover as="template" v-slot="{ open }">
      <header
        :class="[open ? 'fixed inset-0 overflow-y-auto' : '', 'w-full z-40 bg-white border-b shadow-sm md:fixed md:top-0 md:left-0 lg:overflow-y-visible']">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div class="relative flex justify-between xl:grid xl:grid-cols-12 lg:gap-8">
            <div class="flex md:absolute md:left-0 md:inset-y-0 lg:static xl:col-span-2">
              <div class="flex-shrink-0 flex items-center">
                <router-link to="/">
                  <img src="/img/icon.svg" class="block h-8 w-auto text-emerald-600" alt="Locktor" />
                </router-link>
              </div>
            </div>
            <div class="min-w-0 flex-1 md:px-8 lg:px-0 xl:col-span-7">
              <div class="flex items-center px-6 py-4 md:max-w-3xl md:mx-auto lg:max-w-none lg:mx-0 xl:px-0">
                <div class="w-full flex justify-start md:justify-end">
                  <label for="search" class="sr-only">Search</label>

                  <button
                    class="relative group flex items-center text-gray-500 transition duration-200 hover:text-gray-700 sm:w-auto"
                    type="button" @click="openSearchPalette">
                    <span class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                      <MagnifyingGlassIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                    <span id="search" name="search"
                      class="block w-full rounded-md py-1 pl-10 text-sm focus:outline-none focus:text-gray-900 sm:text-sm sm:py-2">Search&nbsp;&nbsp;</span>

                    <span
                      class="hidden w-full px-3 ml-1 border border-gray-400 rounded-md transition duration-300 group-hover:text-emerald-600 group-hover:border-emerald-600 group-hover:font-semibold md:block">
                      Ctrl &nbsp;/
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div class="flex items-center md:absolute md:right-0 md:inset-y-0 lg:hidden">
              <!-- Mobile menu button -->
              <PopoverButton
                class="-mx-2 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-emerald-500">
                <span class="sr-only">Open menu</span>
                <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
                <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
              </PopoverButton>
            </div>
            <div class="hidden lg:flex lg:items-center lg:justify-end xl:col-span-3">
              <a href="#"
                class="ml-5 flex-shrink-0 bg-white rounded-full p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                <span class="sr-only">View notifications</span>
                <BellIcon class="h-6 w-6" aria-hidden="true" />
              </a>

              <!-- Profile dropdown -->
              <Menu as="div" class="flex-shrink-0 relative ml-5">
                <div>
                  <MenuButton
                    class="bg-white rounded-full flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                    <span class="sr-only">Open user menu</span>
                    <span class="inline-block h-8 w-8 overflow-hidden rounded-full bg-gray-200">
                      <ProfileAvatar class="h-full w-full text-gray-500" />
                    </span>
                  </MenuButton>
                </div>
                <transition enter-active-class="transition ease-out duration-100"
                  enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95">
                  <MenuItems
                    class="origin-top-right absolute z-10 right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 py-1 focus:outline-none">
                    <MenuItem v-for="item in userNavigation" :key="item.name" v-slot="{ active }">
                    <router-link :to="item.href"
                      :class="[active ? 'bg-gray-100' : '', 'block py-2 px-4 text-sm text-gray-700']">{{ item.name
                      }}</router-link>
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                    <a href="/" @click.prevent="signOut"
                      :class="[active ? 'bg-gray-100' : '', 'block py-2 px-4 text-sm text-gray-700']">
                      Sign out
                    </a>
                    </MenuItem>
                  </MenuItems>
                </transition>
              </Menu>

              <button @click="addSecret"
                class="ml-6 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                Add Item +20 XP </button>
            </div>
          </div>
        </div>

        <PopoverPanel as="nav" class="lg:hidden" aria-label="Global">
          <div class="max-w-3xl mx-auto px-2 pt-2 pb-3 space-y-1 sm:px-4">
            <a v-for="item in navigation" :key="item.name" :href="item.href"
              :aria-current="item.current ? 'page' : undefined"
              :class="[item.current ? 'bg-gray-100 text-gray-900' : 'hover:bg-gray-50', 'block rounded-md py-2 px-3 text-base font-medium']">{{
                item.name }}</a>
          </div>
          <div class="border-t border-gray-200 pt-4 pb-3">
            <div class="max-w-3xl mx-auto px-4 flex items-center sm:px-6">
              <div class="flex-shrink-0">
                <span class="inline-block h-10 w-10 overflow-hidden rounded-full bg-gray-200">
                  <ProfileAvatar class="h-full w-full text-gray-600" />
                </span>
              </div>
              <div class="ml-3">
                <div class="text-base font-medium text-gray-800">{{ user?.displayName || '--' }}</div>
                <div class="text-sm font-medium text-gray-500">{{ user?.email || '--' }}</div>
              </div>
              <button type="button"
                class="ml-auto flex-shrink-0 bg-white rounded-full p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                <span class="sr-only">View notifications</span>
                <BellIcon class="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div class="mt-3 max-w-3xl mx-auto px-2 space-y-1 sm:px-4">
              <router-link v-for="item in userNavigation" :key="item.name" :to="item.href"
                class="block rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900">{{
                  item.name }}</router-link>
              <a href="/" @click.prevent="signOut"
                class="block rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900">Sign
                out</a>
            </div>
          </div>
        </PopoverPanel>
      </header>
    </Popover>

    <div class="max-w-7xl mx-auto px-4 sm:px-6 md:pt-16 lg:px-8">
      <VerticalNav class="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-40" />
      <div v-if="inactiveSubscription" class="hidden mt-1 border-l-4 border-yellow-400 bg-yellow-50 p-4">
        <div class="flex lg:justify-center">
          <div class="flex-shrink-0">
            <ExclamationTriangleIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
          </div>
          <div class="ml-3">
            <p class="text-sm text-yellow-700 leading-normal">
              You don't have an active subscription. Most features will not work.
              {{ ' ' }}
              <br class="sm:hidden" />
              <router-link to="/plans/choose" class="font-medium text-yellow-700 underline hover:text-yellow-600">
                Activate your account now &rarr;
              </router-link>
            </p>
          </div>
        </div>
      </div>
      <div class="pt-6 md:pt-12 md:pl-72 lg:pl-80">
        <slot />
      </div>
    </div>

    <!-- Mobile bottom nav -->
    <nav class="fixed bottom-0 left-0 block w-full bg-white px-4 py-3 border-t shadow-md shadow-black md:hidden">
      <div class="w-full">
        <div class="flex justify-between max-w-[380px] mx-auto">

          <router-link to="/" class="block text-gray-600 font-medium" active-class="text-emerald-600 font-semibold">
            <span class="flex justify-center">
              <HomeIcon class="h-4 w-4" />
            </span>
            <span class="block text-[10px] text-center">Home</span>
          </router-link>

          <button @click="addSecret" class="block text-gray-600 font-medium" type="button">
            <span class="flex justify-center">
              <PlusIcon class="h-4 w-4" />
            </span>
            <span class="block text-xs text-center">New Item</span>
          </button>

          <button @click="openSearchPalette" class="block text-gray-600 font-medium" type="button">
            <span class="flex justify-center">
              <MagnifyingGlassIcon class="h-4 w-4" />
            </span>
            <span class="block text-xs text-center">Search</span>
          </button>

          <button @click="lock" class="block text-gray-600 font-medium" type="button">
            <span class="flex justify-center">
              <LockClosedIcon class="h-4 w-4" />
            </span>
            <span class="block text-xs text-center">Lock</span>
          </button>
        </div>
      </div>
    </nav>
  </div>

  <slide-over class="mt-48 bg-emerald-300" :open="miscStore.slideOver.open" :close="miscStore.slideOver.close"
    :child="miscStore.slideOver.child" :heading="miscStore.slideOver.heading" />

  <search-palette :open="state.searchPaletteOpen" :close="closeSearchPalette" />
  <EarnedXpModal :open="miscStore.earnedXpModal.open" :close="miscStore.closeEarnedXpModal"
    :amount="miscStore.earnedXpModal.amount" />
  <idle-lock />
  <live-notification-region />
</template>

<script lang="ts" setup>
import { computed, reactive, ref } from 'vue'
import { Menu, MenuButton, MenuItem, MenuItems, Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { MagnifyingGlassIcon } from '@heroicons/vue/24/outline'
import { BellIcon, HomeIcon, Bars3Icon, LockClosedIcon, PlusIcon, XMarkIcon } from '@heroicons/vue/24/outline'
import { ExclamationTriangleIcon } from '@heroicons/vue/20/solid'
import IdleLock from '@/components/IdleLock.vue'
import EarnedXpModal from '@/components/EarnedXpModal.vue'
import LiveNotificationRegion from '@/components/Notifications/LiveRegion.vue'
import VerticalNav from '@/components/VerticalNav.vue'
import SlideOver from '@/components/SlideOver.vue'
import SearchPalette from '@/components/SearchPalette.vue'
import ProfileAvatar from '@/components/ProfileAvatar.vue'
import { useMiscStore } from '@/stores/misc'
import AddSecret from '../views/AddSecret/AddSecret.vue'
import { useAuthStore } from '../stores/auth'
import { auth } from '@/firebase'
import { tracker } from '@/amplitude'

const miscStore = useMiscStore()
const authStore = useAuthStore()

const user = ref(authStore.user)

const navigation = <Array<object>>[
  // { name: 'Dashboard', href: '#', current: true },
  // { name: 'Calendar', href: '#', current: false },
  // { name: 'Teams', href: '#', current: false },
  // { name: 'Directory', href: '#', current: false },
]
const userNavigation = <Array<{ name: string, href: string }>>[
  // { name: 'Your Profile', href: '#' },
  { name: 'Settings', href: '/settings' },
  // { name: 'Sign out', href: '#' },
]
const state = reactive({ searchPaletteOpen: false })

const addSecret = () => {
  miscStore.openSlideOver('Add a new Item', AddSecret)
}

const lock = () => window.location.reload()

const signOut = () => {
  const ok = confirm('Sign out from this device?')

  if (ok) {
    authStore.signOut().then(() => lock())
  }
}

const openSearchPalette = () => {
  state.searchPaletteOpen = true
  tracker.openedSearchBox()
}
const closeSearchPalette = () => state.searchPaletteOpen = false

const searchKeyboardShortcut = (e: KeyboardEvent) => {
  if (e.ctrlKey && e.key === '/') {
    e.preventDefault()

    if (state.searchPaletteOpen) {
      return
    }
    openSearchPalette()
  }
}

const inactiveSubscription = computed(() => {
  const sess = authStore.session

  return sess && (
    !sess.subscription
    || Date.now() > (sess.subscription?.expires || 0) * 1000)
})

document.addEventListener('keyup', searchKeyboardShortcut)
</script>
