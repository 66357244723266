<template>
  <component :is="layout">
    <!-- https://github.com/vuejs/router/issues/1257#issuecomment-2072052011-->
    <RouterView :key="$route.fullPath" />
  </component>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { RouterView } from 'vue-router'
import DefaultLayout from './layout/default.vue'
import AuthLayout from './layout/auth.vue'
import BlankLayout from './layout/blank.vue'
import TeamsDashboardLayout from './layout/teams-dashboard/teams-dashboard.vue'
import PasswordManagerDashboardLayout from './layout/password-manager-dashboard.vue'
import { useAuthStore } from './stores/auth'
import { auth } from './firebase'

export default defineComponent({
  components: {
    RouterView,
    AuthLayout,
    BlankLayout,
    DefaultLayout,
    PasswordManagerDashboardLayout,
    TeamsDashboardLayout,
  },

  computed: {
    layout(): string {
      const name = this.$route.meta.layout || 'default'

      return name + '-layout'
    },

    requiresAuth(): boolean {
      return !!this.$route.meta.requiresAuth
    }
  },

  created() {
    const authStore = useAuthStore()

    auth.onAuthStateChanged(user => {
      authStore.setUser(user)
    })
  }
})

</script>

<style>
@import '@/assets/base.css';

</style>
