<template>
  <InformationModal
    :open="state.modal"
    :close="closeModal"
    title="Idle Timeout"
    description="To protect your private information, your app will be locked soon if it stays idle..."
    btnText="Stay unlocked"
    status="warning"
  />
</template>

<script lang="ts" setup>
import { reactive, computed, watch, onMounted } from 'vue'
import { storeToRefs } from 'pinia'
import IdleJs from 'idle-js'
import { useAuthStore } from '@/stores/auth'
import { useKpkStore } from '@/stores/kpk'
import InformationModal from './InformationModal.vue'
import { useSettingsStore } from '@/stores/settings'

const authStore = useAuthStore()
const kpkStore = useKpkStore()
const settingsStore = useSettingsStore()

const { settings } = storeToRefs(settingsStore)

const state = reactive({ modal: false })
const closeModal = () => state.modal = false

const lockAccount = () => {
  if (authStore.signedIn && kpkStore.keyedUp) {
    window.location.reload()
  }
}
// lock account after [x] mins of tab inactivity. x defaults to 7
const lockTime = computed(() => (Number(settings.value.keyring.idleLockMins) || 7) * 60 * 1000)
const warningTime = computed(() => lockTime.value - (30 * 1000)) // show warning 30 seconds prior

let lockCounter: IdleJs, warningCounter: IdleJs

const configureCounters = () => {
  lockCounter = new IdleJs({ idle: lockTime.value, onIdle: lockAccount }).start()
  warningCounter = new IdleJs({
    idle: warningTime.value,
    onIdle: () => state.modal = true,
    // auto-close warning after 6 seconds of tab reactivation
    onActive: () => setTimeout(closeModal, 6 * 1000),
  }).start()
}

onMounted(() => configureCounters())

watch(lockTime, (newTime, oldTime) => {
  if (newTime === oldTime) {
    return
  }
  if (lockCounter && warningTime) {
    lockCounter.stop()
    warningCounter.stop()
    configureCounters()
  }
})

</script>
