import { defineStore } from 'pinia'
import { useAuthStore } from './auth'

type Bucket = 'main' | 'keyring'

export const useSettingsStore = defineStore({
  id: 'settings',

  state: () => ({
    storage: {
      main: <SettingsStorage<MainSettingsItems> | null> null, // general / teams
      keyring: <SettingsStorage<KeyringSettingsItems> | null> null, // password manager
    },
    /**
     * Consider this property read-only. Any changes made directly to it
     * will NOT be saved until the next time `[section].setIten()` is called.
     *
     * It may not be called.
     */
    settings: {
      main: <MainSettingsItems>{},
      keyring: <KeyringSettingsItems>{}
    },
  }),

  getters: {
  },

  actions: {
    keyring() {
      const updateFn = (k: keyof KeyringSettingsItems, v: any) => this.settings.keyring[k] = v

      return this.storage.keyring || (
        this.storage.keyring = new SettingsStorage<KeyringSettingsItems>('keyring', updateFn)
      )
    },

    main() {
      const updateFn = (k: keyof MainSettingsItems, v: any) => this.settings.main[k] = v

      return this.storage.main || (
        this.storage.main = new SettingsStorage<MainSettingsItems>('main', updateFn)
      )
    }
  },
})

export type MainSettingsItems = {
  'session.currentOrgId': string
}

export type KeyringSettingsItems = {
  idleLockMins: number
}

// interface StorageDriver {
//   get(key: string): any
//   set(key: string, value: any): void
// }


class SettingsStorage<BucketItems> {
  protected driver: Storage | undefined
  protected readonly bucket: string
  protected settings
  protected updater

  constructor(bucket: Bucket, updater: (k: keyof BucketItems, v: any) => void) {
    if (!(bucket && updater)) {
      // throw
    }
    this.bucket = 'io.locktor.app.settings__' + bucket

    const { user } = useAuthStore()
    if (user?.uid) {
      this.bucket += `.${user.uid}`
    }

    if (window && window.localStorage) {
      this.driver = window.localStorage
    }
    this.settings = JSON.parse(this.driver?.getItem(this.bucket) || '{}')
    this.updater = updater

    // populate initial retrieved state
    Object.keys(this.settings).forEach(k => this.updater(<keyof BucketItems>k, this.settings[k]))
  }

  getItem(key: keyof BucketItems): string | boolean | object | null {
    try {
      const value = this.settings[key]
      if (value !== null && typeof value === 'object') {
        // return clones of object fields to avoid implicit side-effects
        return { ...value }
      }
      return value
    }
    catch {
      return null
    }
  }

  setItem(key: keyof BucketItems, value: any): void {
    this.settings[key] = value
    this.updater(key, value)

    if (!this.driver) return
    this.driver.setItem(this.bucket, JSON.stringify(this.settings))
  }
}
