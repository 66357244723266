import { Capacitor } from '@capacitor/core'
import { defineStore } from 'pinia'
import { useAuthStore } from './auth'
import { useKpkStore } from './kpk'
import * as funcs from '@/crypto/functions'

const apiRoot = import.meta.env.VITE_API_URL

interface BaseVault {
  id: string,
  ownerId: string,
  name: string,
}

interface RemoteVault extends BaseVault {
  key: {
    cipher: string,
    algo: string,
  },
  items: Record<string, {
    content: string,
    iv: string
  }>
}

export interface Vault extends BaseVault {
  key: CryptoKey,
  items: Record<string, VaultItem>
}

export type SecretType = Password

export interface Password {
  type: 'password',
  username: string,
  password: string,
  website: string,
  title: string,
}

type VaultItemKind = 'website-login' | 'freetext' | 'payment-card'

export class VaultItem {
  kind: VaultItemKind
  fields?: Record<string, string | object>
  created?: Date
  lastModified?: Date

  extraFields?: Record<string, {
    kind: 'string'
    name: string
    isSecret: boolean
    value?: string
  }>

  constructor (item: Partial<VaultItem>, isNew = false) {
    this.kind = <VaultItemKind>item.kind || 'website-login'

    if (item.fields) {
      this.fields = item.fields
    }
    if (isNew) {
      this.created = this.lastModified = new Date()
    }
    if (!this.lastModified) {
      this.lastModified = new Date()
    }
  }

  static from(object: Record<string, string | object>) {
    if (!object.kind || object.kind === 'website-login') {
      return new WebsiteLogin(object)
    }

    return new VaultItem(object)
  }
}

export class WebsiteLogin extends VaultItem {
  declare fields?: {
    title?: string
    website?: string
    username?: string
    password?: string
  }

  constructor(item: Partial<WebsiteLogin>, isNew = false) {
    super(item as Partial<WebsiteLogin>, isNew)

    if (item.fields) {
      this.fields = <typeof this.fields>item.fields
    }

    // for website logins that haven't been converted to the new format
    const password: Record<string, string | object> = item
    if (password.title || password.website) {
      this.fields = {
        title: <string>password.title,
        website: <string>password.website,
        username: <string>password.username,
        password: <string>password.password
      }
      this.created = new Date('2024-06-26T04:30:00Z')
    }
  }
}

export class PaymentCard extends VaultItem {
  expiryMonth?: string
  expiryYear?: number
  cvv?: string
  title?: string
}

export const useVaultsStore = defineStore({
  id: 'vaults',

  state: () => ({
    vaults: <Record<string, Vault>> {},
  }),

  getters: {
    authUser: () => useAuthStore().user,
  },
  actions: {
    /**
     * Find a secret by id.
     */
    getSecret(vaultId: string, secretId: string): VaultItem | null {
      if (!Object.keys(this.vaults).includes(vaultId)) {
        return null
      }

      const vault = this.vaults[vaultId]
      if (!Object.keys(vault.items).includes(secretId)) {
        return null
      }

      return vault.items[secretId]
    },

    /**
     * Pulls all of the current customer's vaults.
     */
    async pullAll() {
      const result = await fetch(`${apiRoot}/vaults`, {
        headers: { Authorization: `Bearer ${await this.authUser?.getIdToken()}` },
      })

      if (!result.ok) /* problem */ return

      const vaults: Array<RemoteVault> = await result.json()
      const { privateKey } = useKpkStore()

      vaults.forEach(async (vault) => {
        const localVault = <Vault>{
          id: vault.id,
          name: vault.name,
          ownerId: vault.ownerId,
          items: {}
        }

        // decrypt vault key
        const clear = await crypto.subtle.decrypt(
          { name: 'RSA-OAEP' },
          <CryptoKey>privateKey,
          funcs.textToArrayBuffer(vault.key.cipher)
        )

        localVault.key = await crypto.subtle.importKey(
          'raw', clear, 'AES-GCM', false, ['encrypt', 'decrypt']
        )

        // decrypt packet contents
        for (const id in vault.items) {
          const packet = vault.items[id]
          const iv = new Uint32Array(funcs.toNumberArray(packet.iv))

          const content = await crypto.subtle.decrypt(
            { name: 'AES-GCM', iv },
            localVault.key,
            funcs.textToArrayBuffer(packet.content)
          )

          localVault.items[id] = VaultItem.from(JSON.parse(
            (new TextDecoder()).decode(content)
          ))
        }

        this.vaults[vault.id] = localVault
      })
    },

    /**
     * Store a secret in a vault.
     *
     * @param {VaultItem} secret Information to store as packet
     * @param {String} id Vault id
     *
     * @returns {Promise<object>}
     */
    async addSecret(secret: VaultItem, id: string) {
      const vault = this.vaults[id]

      if (vault === undefined) return // problem

      const iv = crypto.getRandomValues(new Uint32Array(24))
      const content = await this.encryptSecret(secret, vault.key, iv)

      const result = await fetch(`${apiRoot}/vaults/${id}/packets`, {
        method: 'post',
        headers: {
          Authorization: `Bearer ${await this.authUser?.getIdToken()}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          content: funcs.arrayBufferToText(content),
          iv: iv.toString(),
          platform: Capacitor.getPlatform(),
        })
      })

      if (!result.ok) return false

      const body = await result.json()
      vault.items[body.id] = secret

      return body
    },

    /**
     *
     * @param {string} id Secret id
     * @param {string} vaultId Vault id
     * @param {VaultItem} update The updated secret object
     * @returns
     */
    async updateSecret(id: string, vaultId: string, update: VaultItem) {
      const vault = this.vaults[vaultId]

      if (vault === undefined) return // problem

      const iv = crypto.getRandomValues(new Uint32Array(24))
      const content = await this.encryptSecret(update, vault.key, iv)

      const res = await fetch(`${apiRoot}/vaults/${vaultId}/packets/${id}`, {
        method: 'put',
        headers: {
          Authorization: `Bearer ${await this.authUser?.getIdToken()}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          content: funcs.arrayBufferToText(content),
          iv: iv.toString()
        })
      })

      if (!res.ok) return false

      vault.items[id] = Object.assign({}, update)

      return true
    },

    async removeSecret(id: string, vaultId: string): Promise<boolean> {
      const response = await fetch(`${apiRoot}/vaults/${vaultId}/packets/${id}`, {
        method: 'delete',
        headers: { Authorization: `Bearer ${await this.authUser?.getIdToken()}` }
      })

      if (!response.ok) {
        return false
      }

      delete this.vaults[vaultId].items[id]

      return true
    },

    /**
     *
     * @param secret
     * @param key
     * @param iv
     */
    encryptSecret(secret: VaultItem, key: CryptoKey, iv: Uint32Array): Promise<ArrayBuffer> {
      const input = (new TextEncoder()).encode(JSON.stringify(secret))

      return crypto.subtle.encrypt(
        { name: 'AES-GCM', iv },
        key,
        input
      )
    },

    /**
     * Get a possible icon for an item
     *
     * @param {VaultItem} item
     * @returns {string}
     */
    possibleIconFor(item: VaultItem): string {
      if (!((item instanceof WebsiteLogin) && item.fields?.website)) {
        return ''
      }
      const url = new URL(item.fields.website)

      return `${apiRoot}/logo/` + url.host
    },
  }
})
