import { defineStore } from 'pinia'
import type { User, UserCredential } from '@firebase/auth'
import { auth } from '@/firebase'
import amplitude from '@/amplitude'
import { digestMessage } from '@/digest'

const apiRoot = import.meta.env.VITE_API_URL

interface Session {
  id: string,
  nickname: string,
  subscription: null | {
    subscription_code: string,
    expires: number,
    nextCharge: number,
  },
  game?: {
    xp?: number,
    rank?: number,
  },
  avatarUrl?: string,
  ownedOrgIds?: string[]
}

export const useAuthStore = defineStore('auth', {
  state: () => ({
    user: <User|null> null,
    session: <Session|null> null,
    signInRedirectResult: <UserCredential|null> null,
  }),

  actions: {
    setSignInRedirectResult(result: UserCredential) {
      this.signInRedirectResult = result
    },

    consumeSignInRedirectResult(): UserCredential | null {
      let result = null

      if (this.signInRedirectResult) {
        result = { ...this.signInRedirectResult }
        this.signInRedirectResult = null
      }

      return result
    },

    /**
     * Set the currently authenticated user.
     *
     * @param {User|null} user User profile or `null` to log the current one out.
     */
    setUser(user: User | null) {
      this.user = user

      if (user) {
        // note uid in amplitude
        digestMessage(user.uid).then(
          hash => amplitude.setUserId(hash)
        )
      }
    },

    async refreshSession() {
      return fetch(`${apiRoot}/auth/session`, {
        headers: { Authorization: `Bearer ${await this.user?.getIdToken()}` }
      })
        .then(async (response) => {
          if (response.ok) {
            return this.$patch({
              session: await response.json()
            })
          }
        })
        .catch(() => null)
    },

    signOut() {
      this.setUser(null)
      this.session = null

      return auth.signOut()
    }
  },

  getters: {
    signedIn: (store): boolean => store.user !== null
  }
})
/**
 * ALL FRESH LOGINS for now i.e. no persistence in device/local storage
 *
 * On sigunp
    * same process as on fresh login
 *
 * On fresh login
    * Store keyingMaterial(?) (to decrypt private key)
    * pull asymmetric keyset & decrypt private key
    * pull all vaults (inc. keys and items)
    * foreach vault:
        * decrypt vault key (using private kpk)
        * decrypt packet contents (using vault key)
  *
  * On adding an item
    * collect information
    * encrypt as packet and push to api
    * store information, along with new packet id, in pinia
  *
  * On editing an item
    * collect information
    * encrypt as packet and push to api
    * update information in store by vault/packet-id
  *
  * On deleting an item, delete with api by packet-id and delete from store
 */
