<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-50" @close="closeDialog">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
              <div>
                <div class="flex justify-between">
                  <div></div>
                  <div class="flex items-center">
                    <OrbitIcon class="h-4 w-4 mr-1 text-indigo-600" />
                    <span id="earned-xp-animate-value" class="text-gray-600 font-medium">{{ game?.xp || 0 }}</span>
                  </div>
                </div>
                <div class="mt-6 mx-auto flex items-center justify-center rounded-full">
                  <OrbitIcon class="h-32 w-32 text-indigo-600 motion-safe:animate-pulse" aria-hidden="true" />
                </div>
                <div class="mt-6 text-center sm:mt-5">
                  <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">
                    You earned {{ amount }} XP!
                  </DialogTitle>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500">
                      Well done! Keep earning XP to advance to the next level.
                    </p>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-6">
                <button type="button"
                  class="inline-flex w-full justify-center rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                  @click="closeDialog">Continue</button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import OrbitIcon from '@/components/icons/MDI/OrbitIcon.vue'
import { useAuthStore } from '../stores/auth'

const props = defineProps<{
  open?: boolean,
  close: () => any,
  amount: number,
}>()
const authStore = useAuthStore()
let game = ref(authStore.session?.game)

// animate xp increase everytime this modal is opened
watch(
  () => props.open,
  newValue => {
    if (newValue !== true) {
      return
    }
    setTimeout(() => animateValue(
      game,
      game.value?.xp || 0,
      (game.value?.xp || 0) + props.amount,
      1400,
    ), 800)
  }
)

function closeDialog() {
  authStore.refreshSession()
  props.close()
}

function animateValue(obj: typeof game, start: number, end: number, duration: number) {
  let startTimestamp: number | null = null

  const step = (timestamp: DOMHighResTimeStamp) => {
    if (!startTimestamp) startTimestamp = timestamp

    const progress = Math.min((timestamp - startTimestamp) / duration, 1)
    if (obj.value) {
      obj.value.xp = Math.floor(progress * (end - start) + start)
    }
    if (progress < 1) {
      window.requestAnimationFrame(step);
    }
  };
  window.requestAnimationFrame(step);
}
</script>
