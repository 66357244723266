import api from '@/api'
import { defineStore } from 'pinia'
import { markRaw } from 'vue'
import type { Component } from 'vue'

export interface ReportType {
  id: string
  orgId: string
  github: {
    id: number
    login: string
    accountType: string
  }
  periodStart: string
  periodEnd: string
  score: number
  avgTimeToFix: number
  repos: Record<string, {
    id: number
    name: string
    fullName: string
    private: boolean
    defaultBranch: string
    dependabot: {
      totalAlerts: number
      totalTimeToFix: number
    }
    alertResolution: { open: number, fixed: number, dismissed: number }
    unresolvedAlerts: {
      critical: number
      high: number
      medium: number
      low: number
    }
    securityAnalysis: null | Record<string, string|number|object>
  }>
  createdAt: string
}

export const useMiscStore = defineStore({
  id: 'misc',

  state: () => ({
    slideOver: {
      open: false,
      child: <Component|null> null,
      heading: '',
      close: () => false,
    },

    earnedXpModal: {
      open: false,
      amount: 0,
    },

    securityReports: <Record<string, ReportType>>{}
  }),
  getters: {
  },

  actions: {
    openSlideOver(heading: string, child: Component): void {
      this.slideOver.open = true
      this.slideOver.child = markRaw(child)
      this.slideOver.heading = heading
      this.slideOver.close = () => this.slideOver.open = false
    },

    closeSlideOver() {
      this.slideOver.open = false
    },

    openEarnedXpModal(amount: number) {
      if (!amount) {
        return
      }
      this.earnedXpModal.open = true
      this.earnedXpModal.amount = amount
    },

    closeEarnedXpModal() {
      this.earnedXpModal.open = false
      this.earnedXpModal.amount = 0

      if (document && document.documentElement) {
        // looks like, when a modal is closed, HeadlessUI reinstates whatever
        // the value of "overflow" was on the HTML tag prior to opening the modal.
        // Unfortunately, that prior value is "hidden" right now 'cause we also
        // add secret items using a modal :(. Hence:
        setTimeout(() => {
          document.documentElement.style.overflow = 'inherit'
        }, 500)
      }
    },

    async getSecurityReport(orgId: string) {
      if (this.securityReports[orgId]) {
        /** @todo see if it's of today */
        return this.securityReports[orgId]
      }

      const result = await api.get(`/${orgId}/security-reports/current`).catch(() => null)
      if (!result?.ok) {
        return null
      }
      await this.$patch({
        securityReports: { [orgId]: await result.json() }
      })

      return this.securityReports[orgId]
    }
  }
})
