import { defineStore } from 'pinia'
import api from '@/api'
import { useSettingsStore } from './settings'

interface Session {
  id: string,
  nickname: string,
  subscription: null | {
    subscription_code: string,
    expires: number,
    nextCharge: number,
  },
  game?: {
    xp?: number,
    rank?: number,
  },
  avatarUrl?: string,
  ownedOrgIds?: string[],
  orgs?: Record<string, OrgRecord>
}

type OrgRecord = {
  id: string,
  name: string,
  createdAt: string,
  github?: {
    id: number
    login: string
    accountType: string
  }
}

/**
 * All things user's-current-session
 */
export const useSessionStore = defineStore('session', {
  state: () => ({
    session: <Session | null> null,
    currentOrgId: <string | null> null,
  }),

  getters: {
    /**
     * Return the org. that the current user is interacting with, if any.
     *
     * @param state
     * @returns
     */
    currentOrg(state): OrgRecord | undefined {
      if (!state.session?.orgs) {
        return undefined
      }

      // if our current org is set, and available, return it
      if (state.currentOrgId) {
        return state.session.orgs[state.currentOrgId]
      }

      const settingsStore = useSettingsStore()
      let orgId = settingsStore.main().getItem('session.currentOrgId')
      if (!orgId || !state.session?.ownedOrgIds?.includes(orgId as string)) {
        // if there's no ownedOrg set as the current in device storage,
        // we'll pick the first org that this customer owns, and make that the current org.
        orgId = state.session.ownedOrgIds && state.session.ownedOrgIds.length
          ? state.session.ownedOrgIds[0]
          : null

        settingsStore.main().setItem('session.currentOrgId', orgId)
      }

      return orgId ? state.session.orgs[String(orgId)] : undefined
    }
  },

  actions: {
    async refreshSession() {
      return api.get(`/auth/session`)
        .then(async (response) => {
          if (response.ok) {
            this.$patch({ session: await response.json() })
          }
        })
        .catch(() => null)
    },

    setCurrentOrg(orgId: string) {
      if (this.session?.ownedOrgIds?.includes(orgId)) {
        useSettingsStore().main().setItem('session.currentOrgId', orgId)
        this.currentOrgId = orgId
      }
    }
  }
})
