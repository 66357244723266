<template>
  <div class="min-h-screen relative py-8">
    <div class="w-full h-full px-4">
      <PageSpinner />
    </div>
  </div>

  <EarnedXpModal
    :open="miscStore.earnedXpModal.open"
    :close="miscStore.closeEarnedXpModal"
    :amount="miscStore.earnedXpModal.amount"
  />
  <live-notification-region />
</template>

<script lang="ts" setup>
import EarnedXpModal from '@/components/EarnedXpModal.vue'
import LiveNotificationRegion from '@/components/Notifications/LiveRegion.vue'
import { useMiscStore } from '@/stores/misc'
import PageSpinner from '@/components/PageSpinner.vue'

const miscStore = useMiscStore()
const loadingText = [
  "Debugging the loading screen. It's a vicious cycle.",
  "Trying to decipher our own code from last week... who even wrote this mess?",
  "Restarting the server again. Maybe this time it'll fix itself :fingers_crossed:"
]

</script>
