
const pointsToRank = {
  5: 1,
  50: 2,
  550: 3,
  1570: 4,
  3020: 5,
  5100: 6,
  8225: 7,
  12235: 8,
  17345: 9,
  23600: 10,
}

const ranksToPoints = {
  1: 5,
  2: 50,
  3: 550,
  4: 1570,
  5: 3020,
  6: 5100,
  7: 8225,
  8: 12235,
  9: 17345,
  10: 23600,
}

export function pointsNeededForRank(rank: number) {
  return ranksToPoints[<1>rank]
}
