import * as amplitude from '@amplitude/analytics-browser';
import { Capacitor } from '@capacitor/core'

if (import.meta.env.VITE_AMPLITUDE_API_KEY) {
  amplitude.init(import.meta.env.VITE_AMPLITUDE_API_KEY, {
    defaultTracking: {
      formInteractions: false,
      pageViews: false,
      sessions: true,
      attribution: true,
    },
  })
}
export default amplitude

const EVENT_SIGNED_UP = 'Signed up'
const EVENT_REAUTH = 'Re-authenticated'
const EVENT_SECRET_ADDED_MANUALLY = 'Secret Added Manually'
const EVENT_STRONG_PASS_ADDED = 'Strong Pass Added'
const EVENT_VIEWED_SECRET = 'Viewed Secret Details'
const EVENT_SECRET_COPIED = 'Copied Secret'
const EVENT_OPENED_SEARCH_BOX = 'Opened Search Box'
const EVENT_FOUND_SEARCH_ITEM = 'Found item w/Search'

const defaultOptions = <amplitude.Types.EventOptions> {
  platform: Capacitor.getPlatform(),
}

/** @todo this should probably be defined by a module responsible for managing secret types. */
type SecretKind = 'login'

export const tracker = {
  secretAddedManually(kind: SecretKind, strongPassGenerated = false) {
    if (strongPassGenerated) {
      amplitude.track(EVENT_STRONG_PASS_ADDED, { kind }, defaultOptions)

      return
    }
    amplitude.track(EVENT_SECRET_ADDED_MANUALLY, { kind }, defaultOptions)
  },

  secretCopied(kind: SecretKind) {
    amplitude.track(EVENT_SECRET_COPIED, { kind }, defaultOptions)
  },

  /**
   * New customer!
   */
  signedUp() {
    amplitude.track(EVENT_SIGNED_UP, undefined, defaultOptions)
  },

  /**
   * A signed-in customer reauthenticated by entering their master pass.
   */
  reauthenticated() {
    amplitude.track(EVENT_REAUTH, undefined, defaultOptions)
  },

  /**
   * Customer viewed the details of a single secret.
   */
  viewedSecret() {
    amplitude.track(EVENT_VIEWED_SECRET, undefined, defaultOptions)
  },

  openedSearchBox() {
    amplitude.track(EVENT_OPENED_SEARCH_BOX, undefined, defaultOptions)
  },

  foundSearchItem() {
    amplitude.track(EVENT_FOUND_SEARCH_ITEM, undefined, defaultOptions)
  }
}

/*
 - [x] send hashed UID
 - [x] set environment
 - [x] manually set platform

 - Custom events
 	- [x] Add a login/secret manually
 		- [x] Password was generated at least once during
 	- [x] Copy strong password
  - [x] Sign up
  - [x] Re-authenticate
  - [x] View secret
 	- Login via autofill
 	- Autosave a login
 		- Generate a secure password for autosave
 	- [x] open search box
  - [x] Found sth via Search
*/
