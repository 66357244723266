<template>
  <button
    :disabled="loading"
    :class="[loading ? '!bg-gray-400 cursor-progress flex items-center justify-center' : null]"
  >
    <span v-if="loading" class="mr-3">
      <SimpleSpinner class="h-4 w-auto text-white" />
    </span>

    <slot></slot>
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import SimpleSpinner from './SimpleSpinner.vue'

export default defineComponent({
  components: { SimpleSpinner },
  props: {
    loading: { type: Boolean, default: false }
  }
})
</script>
